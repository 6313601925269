import React from 'react';

import { Container, Segment } from 'semantic-ui-react';

const PersonalProjects = () => {
  return (
    <Container>
      <Segment>HEY SOME PERSONAL PROJECTS SHOULD GO HERE</Segment>
    </Container>
  );
};

export default PersonalProjects;
